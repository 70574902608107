import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetch_elements(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/api/v1/cases/service_bundle`, { params: params.queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetch_element(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/api/v1/cases/service_bundle/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetch_key_value(ctx, { type }) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/v1/core/type_value/' + type, {
                        params: { deep: 1 }
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetch_arls(ctx) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/v1/cases/company', {
                        params: { perPage: 500, page: 1, companyType: 'ARL', only_active: true }
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetch_services(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/api/v1/cases/service`, { params: params.queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        delete_element(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/api/v1/cases/service_bundle/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        add_element(ctx, elementData) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/api/v1/cases/service_bundle`, elementData.data )
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        save_element(ctx, elementData) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/api/v1/cases/service_bundle/${elementData.data.id}`, elementData.data)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}

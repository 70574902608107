<template>
  <div>
    <b-card>
      <b-card-header>
        <div class="text-primary h2">
          <span class="d-none d-sm-inline">{{
            $t(resourcesElementName + '.plural_name')
          }}</span>
        </div>
      </b-card-header>
      <b-card-body class="p-0">
        <div class="mb-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              class="d-flex justify-content-start"
              cols="12"
              md="6">
              <b-button
                variant="primary"
                @click="addGroup">
                <span class="text-nowrap">{{
                  $t(resourcesElementName + '.object.add_group')
                }}</span>
              </b-button>
            </b-col>
          </b-row>
        </div>
        <b-table
          v-if="elementData.groups"
          id="refElementsListTable"
          ref="refElementsListTable"
          :empty-text="$t(resourcesElementName + '.search.not_found')"
          :fields="tableColumns"
          :items="groupsFiltered"
          class="position-relative"
          primary-key="id"
          responsive
          show-empty>
          <template #cell(services)="data">
            <b-table
              ref="refServicesListTable"
              :fields="tableServiceColumns"
              :items="data.item.services"
              class="position-relative"
              primary-key="id"
              responsive />
          </template>

          <!-- Column: actions -->
          <template #cell(actions)="data">
            <b-col class="flex flex-row flex-wrap">
              <b-button
                :id="`edit-button-${data.item.id}`"
                style="margin: 1px"
                variant="primary"
                @click="editService(data.item)">
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                :id="`delete-button-${data.item.id}`"
                style="margin: 1px"
                variant="primary"
                @click="deleteService(data.item.id)">
                <feather-icon icon="DeleteIcon" />
              </b-button>
              <!-- ? EDIT -->
              <b-tooltip
                :target="`edit-button-${data.item.id}`"
                :title="$t('actions.edit')"
                triggers="hover"
                :noninteractive="true"
                fallback-placement="counterclockwise">
              </b-tooltip>
              <!-- ? DELETE -->
              <b-tooltip
                :target="`delete-button-${data.item.id}`"
                :title="$t('actions.delete')"
                triggers="hover"
                :noninteractive="true"
                fallback-placement="counterclockwise">
              </b-tooltip>
            </b-col>
          </template>
        </b-table>
        <div>
          <b-modal
            id="modal-1"
            :title="$t(resourcesElementName + '.object.add')"
            no-close-on-esc
            no-close-on-backdrop
            hide-header-close
            hide-footer
            size="lg">
            <template>
              <search-service-tab :element-data="temporalElementData" />
            </template>
          </b-modal>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BModal,
  BRow,
  BTable,
  BTooltip,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import SearchServiceTab from './SearchService.vue'

export default {
  components: {
    BTooltip,
    SearchServiceTab,
    BDropdown,
    BDropdownItem,
    BModal,
    BTable,
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BFormInput,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BForm,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tableColumns: [],
      tableServiceColumns: [],
      resourcesElementName: '',
      required,
      isNew: false,
      temporalElementData: {},
    }
  },
  computed: {
    groupsFiltered() {
      return this.elementData.groups.filter((element) => element.status == 1)
    },
  },
  beforeDestroy() {
    this.$root.$off('cancel-service')
  },
  mounted() {
    this.$root.$on('cancel-service', () => {
      this.cancelService()
    })
    this.resourcesElementName = 'services'
    this.tableColumns = [
      {
        key: 'name',
        label: this.$t(`${this.resourcesElementName}.object.group_name`),
        sortable: true,
      },
      {
        key: 'services',
        label: this.$t(`${this.resourcesElementName}.object.services`),
        sortable: true,
      },
      {
        key: 'actions',
        label: this.$t(`${this.resourcesElementName}.object.actions`),
      },
    ]
    this.tableServiceColumns = [
      {
        key: 'service.service_type.value',
        label: this.$t(`${this.resourcesElementName}.object.service_type`),
        sortable: true,
      },
      {
        key: 'service.cups',
        label: this.$t(`${this.resourcesElementName}.object.cups`),
        sortable: true,
      },
      {
        key: 'service.cups_name',
        label: this.$t(`${this.resourcesElementName}.object.cups_name`),
        sortable: true,
      },
    ]
  },
  methods: {
    editService(element) {
      this.temporalElementData = element
      this.isNew = false
      this.$root.$emit('bv::show::modal', 'modal-1')
    },
    deleteService(id) {
      if (id < 0) {
        const element = this.elementData.groups.find(
          (element) => element.id == id
        )
        const index = this.elementData.groups.indexOf(element)
        this.elementData.groups.splice(index, 1)
      } else {
        const element = this.elementData.groups.find((element) => element.id == id);
        if (element) { 
          element.status = 0;
          element.state = 0;
          
          element.services.forEach(service => {
            service.status = 0;
            service.state = 0;
          });
        }
      }
    },
    addGroup() {
      this.temporalElementData = {
        id: Math.floor(Math.random() * 100) * -1,
        services: [],
        status: 1,
      }
      this.elementData.groups.push(this.temporalElementData)
      this.isNew = true
      this.$root.$emit('bv::show::modal', 'modal-1')
    },
    cancelService() {
      if (this.isNew) {
        this.isNew = false
        const element = this.elementData.groups.find(
          (element) => element.id == this.temporalElementData.id
        )
        const index = this.elementData.groups.indexOf(element)
        this.elementData.groups.splice(index, 1)
      }
      this.$root.$emit('bv::hide::modal', 'modal-1')
    },
  },
}
</script>

<style></style>

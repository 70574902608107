<template>
  <b-form @submit.prevent="save">
    <validation-observer
      ref="elementForm"
      #default="{ invalid }">
      <b-card>
        <b-card-header>
          <div class="text-primary h2">
            <feather-icon
              icon="StarIcon"
              size="25"
              class="mr-0 mr-sm-50"
              variant="primary" />
            <span class="font-weight-bold">{{
              $t(resourcesElementName + '.name')
            }}</span>
          </div>
        </b-card-header>
        <b-card-body>
          <b-row>
            <!-- Code -->
            <b-col
              cols="4"
              xl="4">
              <b-form-group label-for="code">
                <feather-icon
                  class="mr-75"
                  icon="StarIcon" />
                <span class="font-weight-bold">{{
                  $t(resourcesElementName + '.object.code')
                }}</span
                ><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.code')"
                  rules="required">
                  <b-form-input
                    id="code"
                    v-model="elementData.code"
                    maxlength="10" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Name -->
            <b-col
              cols="4"
              xl="4">
              <b-form-group label-for="name">
                <feather-icon
                  class="mr-75"
                  icon="StarIcon" />
                <span class="font-weight-bold">{{
                  $t(resourcesElementName + '.object.name')
                }}</span
                ><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.name')"
                  rules="required">
                  <v-select
                    v-model="elementData.bundle_name_id"
                    :options="bundleNameList"
                    label="value"
                    value="id"
                    :reduce="(option) => option.id"
                    :placeholder="$t('list.defaultOption')" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="4"
              xl="4">
              <b-form-group label-for="applies_for">
                <feather-icon
                  class="mr-75"
                  icon="StarIcon" />
                <span class="font-weight-bold">{{
                  $t(resourcesElementName + '.object.applies_for')
                }}</span
                ><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.applies_for')"
                  rules="required">
                  <v-select
                    v-model="elementData.applies_for_id"
                    :options="consultTypeList"
                    label="value"
                    value="id"
                    :reduce="(option) => option.id"
                    :placeholder="$t('list.defaultOption')" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- ARL -->
            <b-col
              cols="3"
              md="3">
              <b-form-group label-for="arl">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75" />
                <span class="font-weight-bold">{{
                  $t(resourcesElementName + '.object.arl')
                }}</span
                ><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.arl')"
                  rules="required">
                  <v-select
                    v-model="elementData.arl_id"
                    :options="arlList"
                    label="value"
                    value="id"
                    :reduce="(option) => option.id"
                    :placeholder="$t('list.defaultOption')" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Validity -->
            <b-col
              cols="3"
              md="3">
              <b-form-group label-for="validity">
                <feather-icon
                  class="mr-75"
                  icon="StarIcon" />
                <span class="font-weight-bold">{{
                  $t(resourcesElementName + '.object.validity')
                }}</span
                ><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.validity')"
                  rules="required">
                  <b-form-input
                    id="validity"
                    v-model="elementData.validity"
                    maxlength="4"
                    type="number"
                    :formatter="numberFormat" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Start Date -->
            <b-col
              cols="3"
              md="3"
              lg="3">
              <date-picker
                :element-required="true"
                element-field="start_date"
                :root-element-data="elementData"
                :element-label="
                  $t(resourcesElementName + '.object.start_date')
                " />
            </b-col>

            <!-- End Date -->
            <b-col
              cols="3"
              md="3"
              lg="3">
              <date-picker
                :element-required="true"
                element-field="end_date"
                :root-element-data="elementData"
                :element-label="
                  $t(resourcesElementName + '.object.end_date')
                " />
            </b-col>
          </b-row>
          <b-row />
          <!-- Services -->
          <b-row class="d-flex">
            <b-col
              cols="12"
              md="12">
              <services-edit-tab :element-data="elementData" />
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <b-row class="d-flex justify-content-end">
        <b-button
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="router().go(-1)">
          {{ $t('actions.back') }}
        </b-button>
        <b-button
          variant="primary"
          type="submit"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :disabled="invalid">
          {{ $t('actions.save') }}
        </b-button>
      </b-row>
    </validation-observer>
  </b-form>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BForm,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import DatePicker from '@/views/core/DatePicker.vue'
import ServicesEditTab from '@/views/service/bundles/edit/EditServices.vue'
import router from '@/router'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    DatePicker,
    BCard,
    BButton,
    BRow,
    BCol,
    BFormInput,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BForm,
    ValidationProvider,
    ValidationObserver,
    ServicesEditTab,
    vSelect,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    newElement: {
      type: Boolean,
      required: true,
    },
    resourcesElementName: {
      type: String,
      required: true,
    },
    arlList: {
      type: Array,
      required: true,
    },
    bundleNameList: {
      type: Array,
      required: true,
    },
    consultTypeList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      logoUpdated: false,
      logo: null,
    }
  },
  watch: {
    elementLogo(newValue, oldValue) {
      this.logo = newValue
    },
  },
  methods: {
    router() {
      return router
    },
    numberFormat(value) {
      return value > 9999 ? 9999 : value
    },
    resolveUserStatusVariant(status) {
      if (status == 0) return 'warning'
      return 'success'
    },
    resolveUserStatusMessage(status) {
      if (status == 0) return this.$t('user.statusValue.inactive')
      return this.$t('user.statusValue.active')
    },
    save() {
      this.$refs.elementForm.validate().then((success) => {
        if (success) {
          this.$parent.save(this.elementData)
        }
      })
    },
  },
}
</script>

<style></style>

<template>
  <b-card>
    <validation-observer
      ref="elementForm"
    >
      <b-row>
        <b-col
          cols="12"
        >
          <b-form-group
            label-for="group_name"
          >
            <feather-icon
              class="mr-75"
              icon="StarIcon"
            />
            <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.group_name') }}</span><span
              class="text-danger"
            >*</span>
            <validation-provider
              #default="{ errors }"
              :name="$t(resourcesElementName + '.object.group_name')"
              rules="required"
            >
              <b-form-input
                id="group_name"
                v-model="elementData.name"
                maxlength="250"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-card>
        <b-card-header>
          <div class="text-primary h2">
            <span class="d-none d-sm-inline">{{ $t(resourcesElementName + '.plural_name') }}</span>
          </div>
        </b-card-header>
        <b-card-body>
          <b-row>
            <!-- Right Col: Table -->
            <b-col
              cols="12"
              xl="12"
            >
              <b-form-group
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="query"
                  :placeholder="$t('table.search.search')"
                  class="d-inline-block mr-1"
                  maxlength="60"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-table
            ref="refElementsListTable"
            :empty-text="$t(resourcesElementName + '.search.not_found')"
            :fields="tableColumns"
            :items="internalItems"
            :select-mode="'multi'"
            class="position-relative"
            primary-key="id"
            responsive
            show-empty
          >
            <template #cell(selected)="data">
              <div class="custom-control custom-checkbox">
                <input
                  :id="'check_' + data.index"
                  v-model="data.item.selected"
                  type="checkbox"
                  class="custom-control-input"
                >
                <label
                  class="custom-control-label"
                  :for="'check_' + data.index"
                />
              </div>
            </template>
          </b-table>
          <!-- Pagination -->
          <div class="mx-2 mb-2">
            <b-row class="d-flex align-items-center justify-content-center">
              <b-col
                class="d-flex align-items-center justify-content-center"
                cols="12"
                sm="6"
              >
                <b-pagination
                  v-model="currentPage"
                  :per-page="perPage"
                  :total-rows="total"
                  class="mb-0 mt-1 mt-sm-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card-body>
      </b-card>
      <b-row class="d-flex justify-content-end">
        <b-button
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="cancel()"
        >
          {{ $t('actions.back') }}
        </b-button>
        <b-button
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          type="submit"
          variant="primary"
          @click="save()"
        >
          {{ $t('actions.save') }}
        </b-button>
      </b-row>
    </validation-observer>

  </b-card>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BTable,
  BPagination,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import store from '@/store'
import elementStoreModule from '../storeModule'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BFormInput,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BForm,
    BFormSelect,
    BTable,
    BPagination,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      RESOURCES_ELEMENT_NAME: '',
      ELEMENT_APP_STORE_MODULE_NAME: '',
      required,
      resourcesElementName: 'services',
      items: [],
      internalItems: [],
      total: 1,
      perPage: 5,
      currentPage: 1,
      query: '',
      tableColumns: [],
    }
  },
  watch: {
    currentPage(val) {
      this.internalItems = this.items.slice(5 * (val - 1), 5 * val)
    },
    query(val) {
      this.internalItems = this.items.filter(element => element.cups.toLowerCase().includes(val.toLowerCase()) || element.cups_name.toLowerCase().includes(val.toLowerCase()))
    },
  },
  mounted() {
    this.RESOURCES_ELEMENT_NAME = 'service-bundles'
    this.ELEMENT_APP_STORE_MODULE_NAME = `app-sb-sh-${this.RESOURCES_ELEMENT_NAME}`
    // Register module
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.registerModule(this.ELEMENT_APP_STORE_MODULE_NAME, elementStoreModule)
    this.tableColumns = [
      {
        key: 'selected', label: '✔', sortable: false, class: 'text-center', thStyle: 'width: 40px',
      },
      { key: 'cups', label: this.$t(`${this.resourcesElementName}.object.cups`), sortable: true },
      { key: 'cups_name', label: this.$t(`${this.resourcesElementName}.object.cups_name`), sortable: true },
    ]
    this.fetchElements()
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  methods: {
    cancel() {
      this.$root.$emit('cancel-service')
    },
    save() {
      this.$refs.elementForm.validate().then(success => {
        if (success) {
          this.elementData.services = []
          for (const srv of this.items.filter(item => item.selected)) {
            this.elementData.services.push({ service_id: srv.id, service: srv })
          }
          console.log(this.elementData.services)
          this.$root.$emit('bv::hide::modal', 'modal-1')
        }
      })
    },
    fetchElements() {
      store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_services`, {
          queryParams: {
            perPage: 1000,
            page: 1,
            query: this.query,
          },
        })
        .then(response => {
          this.items = response.data.items.filter(element => element.status == 1)
          this.items = this.items.map(item => {
            console.log(item)
            return {
              ...item,
              status: 1,
              selected: this.elementData.services.some(selectedItem => selectedItem.service_id === item.id),
            }
          })
          this.internalItems = this.items.slice(0, 5)
          this.total = this.items.length
        })
        .catch(error => {
          console.error(error)
        })
    },
  },
}
</script>

<style>

</style>

<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="elementData === undefined">
      <h4 class="alert-heading">
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.fetching_single_data') }}
      </h4>
      <div class="alert-body">
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.check') }}
        <b-link
          class="alert-link"
          :to="{ name: 'apps-' + RESOURCES_ELEMENT_NAME + '-list' }">
          {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.list') }}
        </b-link>
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.to_find_other') }}
      </div>
    </b-alert>

    <template>
      <element-edit-tab
        v-if="elementData && elementData.groups"
        :new-element="newElement"
        :element-data="elementData"
        :arl-list="arlList"
        :bundle-name-list="bundleNameList"
        :consult-type-list="consultTypeList"
        :resources-element-name="RESOURCES_ELEMENT_NAME"
        class="mt-2 pt-75" />
    </template>
  </div>
</template>

<script>
import { BAlert, BLink } from 'bootstrap-vue'
import router from '@/router'
import store from '@/store'
import { mapState } from 'vuex'
import elementStoreModule from '../storeModule'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ElementEditTab from './EditCard.vue'

export default {
  components: {
    BAlert,
    BLink,
    ElementEditTab: ElementEditTab,
  },
  data() {
    return {
      RESOURCES_ELEMENT_NAME: '',
      ELEMENT_APP_STORE_MODULE_NAME: '',
      newElement: true,
      elementData: undefined,
      arlList: [],
      bundleNameList: [],
      consultTypeList: [],
    }
  },
  computed: {
    ...mapState('dataList', ['getDataList']),
  },
  async mounted() {
    this.RESOURCES_ELEMENT_NAME = 'service-bundles'
    this.ELEMENT_APP_STORE_MODULE_NAME =
      'app-sb-ed-' + this.RESOURCES_ELEMENT_NAME
    // Register module
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.registerModule(
        this.ELEMENT_APP_STORE_MODULE_NAME,
        elementStoreModule
      )

    await this.$store
      .dispatch('dataList/fetch_lists', { _country: 'Colombia' })
      .then()
      .catch((error) => {
        console.error('Error fetching data:', error)
      })

    const dataStore_Select = await this.$store.getters['dataList/getDataList']({
      listState: ['arl', 'bundleName', 'caseTrackingType'],
      messageDefault: 'SELECT_ONE',
    })
    this.arlList = dataStore_Select.arl
    this.bundleNameList = dataStore_Select.bundleName
    this.consultTypeList = dataStore_Select.caseTrackingType

    if (router.currentRoute.params.id !== undefined) {
      this.elementData = await this.fetchElement()
    } else {
      this.elementData = {
        status: 1,
        arl_id: null,
        bundle_name_id: null,
        validity_id: null,
        start_date: null,
        end_date: null,
        groups: [],
      }
    }
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  methods: {
    async fetch_arls() {
      return store
        .dispatch(this.ELEMENT_APP_STORE_MODULE_NAME + '/fetch_arls', {})
        .then((response) => {
          var list = response.data.items
          list.unshift({ id: null, name: this.$t('list.defaultOption') })
          return list
        })
        .catch((error) => {
          if (error.response.status === 404) {
            return undefined
          }
        })
    },
    async fetch_key_value(type) {
      return store
        .dispatch(this.ELEMENT_APP_STORE_MODULE_NAME + '/fetch_key_value', {
          type: type,
        })
        .then((response) => {
          var list = response.data.items
          list.unshift({ id: null, value: this.$t('list.defaultOption') })
          return list
        })
        .catch((error) => {
          if (error.response.status === 404) {
            return undefined
          }
        })
    },
    async fetchElement() {
      this.newElement = false
      return store
        .dispatch(this.ELEMENT_APP_STORE_MODULE_NAME + '/fetch_element', {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          var data = response.data

          if (!data.groups) {
            data.groups = []
          }
          return data
        })
        .catch((error) => {
          return []
        })
    },
    save(elementData) {
      console.log("elementData enviado al backend:", elementData);
      let method =
        this.ELEMENT_APP_STORE_MODULE_NAME +
        (this.newElement ? '/add_element' : '/save_element')
      let action = this.newElement ? 'creation' : 'update'
      var _self = this
      store
        .dispatch(method, { data: elementData })
        .then((response) => {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(
                _self.RESOURCES_ELEMENT_NAME +
                  '.action_results.' +
                  action +
                  '.title'
              ),
              icon: 'SaveIcon',
              variant: 'success',
              text: _self.$t(
                _self.RESOURCES_ELEMENT_NAME +
                  '.action_results.' +
                  action +
                  '.success'
              ),
            },
          })
          _self.$router.replace({
            name: 'apps-' + _self.RESOURCES_ELEMENT_NAME + '-list',
          })
        })
        .catch((error) => {
          _self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: _self.$t(
                _self.RESOURCES_ELEMENT_NAME +
                  '.action_results.' +
                  action +
                  '.title'
              ),
              icon: 'SaveIcon',
              variant: 'danger',
              text: _self.$t(
                _self.RESOURCES_ELEMENT_NAME +
                  '.action_results.' +
                  action +
                  '.failure'
              ),
            },
          })
        })
    },
  },
}
</script>

<style></style>
